import React, { useState, useEffect, useReducer } from "react";
import { Container, Nav } from "react-bootstrap";
import { AiOutlineRight, AiFillCopy } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../common/storeContext'
import { TENANT_BUCKET, GLOBAL_BUCKET, GAME_MENU_DATA } from '../../common/constants'
import TransferBalanceDialog from '../../components/TransferBalanceDialog'
import PromoDetailsDialog from '../../components/PromoDetailsDialog'
import Loading from '../../components/Loading';
import Alert from '../../components/Alert'
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import { useMiddletier } from "../../common/middletier";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from 'dayjs';

import "swiper/css";
import "swiper/css/pagination";
import "./template_03.css";


const DYNAMIC_ACTION = {
  SET_DEFAULT_DATA: 'SET_DEFAULT_DATA',
  SET_SHOW_DATA: 'SET_SHOW_DATA',
  SET_FILTER_TYPES: 'SET_FILTER_TYPES',
  SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
  SET_ALL_TYPES: 'SET_ALL_TYPES',
  UPDATE_COUNTDOWN: 'UPDATE_COUNTDOWN',
}

const initialDynamicData = {
  defaultData: [],
  showData: [],
  // filterTypes: [{ code: 'all', name: 'show_all' }],
  // selectedFilterType: { code: 'all', name: 'show_all' },
  allType: [],
  filterTypes: [{ code: 'ALL', name: 'show_all' }],
  selectedFilterType: { code: 'ALL', name: 'show_all' },
}

const dynamicReducer = (state, action) => {
  switch (action.type) {

    case DYNAMIC_ACTION.SET_DEFAULT_DATA: {
      const defaultData = action.payload
      return { ...state, defaultData };
    }

    case DYNAMIC_ACTION.SET_SHOW_DATA: {
      const showData = action.payload
      return { ...state, showData };
    }

    case DYNAMIC_ACTION.UPDATE_COUNTDOWN: {
      const updateCountdown = state.showData.map(promotion => ({
        ...promotion, remainingTime: action.payload(promotion)
      }));

      return { ...state, showData: updateCountdown };
    }


    case DYNAMIC_ACTION.SET_ALL_TYPES: {
      const allType = [...action.payload]
      return { ...state, allType };
    }

    case DYNAMIC_ACTION.SET_FILTER_TYPES: {
      const filterTypes = [...action.payload]
      return { ...state, filterTypes };
    }

    case DYNAMIC_ACTION.SET_SELECTED_FILTER_TYPE: {
      const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
      return { ...state, selectedFilterType };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Template_03 = (props) => {

  const appState = useStoreState()
  // const [dynamicData, setDynamicData] = useState();
  const [dynamicState, dynamicDispatch] = useReducer(dynamicReducer, initialDynamicData)
  const { query, queries, mutation } = useMiddletier();
  const appDispatch = useStoreDispatch()
  // const [remainingTime, setRemainingTime] = useState(null);
  const location = useLocation();
  const [promoDetailsDialogShow, setPromoDetailsDialogShow] = useState(false);
  const [transferDialogShow, setTransferDialogShow] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const navigate = useNavigate();
  const cardContainerProps = props.properties ? props.properties.find((itm) => itm.name === 'Card Container') : false
  const { desktopToggle, mobileToggle, label, id, dataType, titleToggle,titleIconToggle, dynamicQuery, data, filter, noPadding, filterTabToggle, cardPosition } = props;
  const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

  const checkProps = cardContainerProps ? cardContainerProps : props;
  const { contentPosition, actionPosition, rowDesktop, rowMobile, numberOfRow } = checkProps
  const imageSize = cardContainerProps.image ? cardContainerProps.image : props.imageSize

  const { t, i18n } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const [deviceType, setDeviceType] = useState('');
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isLoadingTransfer, setLoadingTransfer] = useState(false);



  const onChangeType = (newValue) => {
    dynamicDispatch({ type: DYNAMIC_ACTION.SET_SELECTED_FILTER_TYPE, payload: newValue })
  }

  const copyText = (newValue) => {
    navigator.clipboard.writeText(newValue);

    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { description: `Copied ${newValue}`, typeAlert: 'success' }
    });
  };


  const onChangePromoDetails = (item) => {
    setCurrentItem(item);
    setPromoDetailsDialogShow(true);
  };

  const onClickGame = (game) => {
    if (appState.loggedIn) {
      setLoading(true);
      // --------------------------------------------------------------
      let currentGame = {
        getCurrentGameType: '',
        getCurrentGameProvider: '',
        getCurrentGameCode: '',
        getCurrentGameUrl: '',
        getCurrentGameAfterGameLink: false,
      }

      if (dataType === 'game provider') {
        // console.log(game)
        // console.log('game_provider_game_types',game._game_provider_game_types)
        currentGame.getCurrentGameType = game._game_provider_game_types[0].type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
        currentGame.getCurrentGameAfterGameLink = game.auto_transfer_after_game_link
      }
      else if (dataType === 'game') {
        currentGame.getCurrentGameType = game.type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      } else {
        currentGame.getCurrentGameType = game.sub_type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      }

      // --------------------------------------------------------
      let mytab

      if (!isInAppBrowser) {
        // OPEN NEW TAB FOR GAME
        mytab = window.open("/loading.html", "_blank");
      }


      // IF GAME PROVIDER AUTO TRANSFER AFTER GAME LINK TRUE (RESTORE FIRST THEN CALL GET GAME LINK)
      if ((dataType === 'game provider' && currentGame.getCurrentGameAfterGameLink)) {

        mutation([{
          method: 'restoreGameWalletByMember',
          params: [
            { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
            { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
            { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
          ],
          attributes: []

        }])
          .then(({ data }) => {
            if (!isInAppBrowser) {
              mytab.location = '/loading_transfer.html'
            }else{
              setLoading(false);
              setLoadingTransfer(true);
            }
            // mytab.location = '/loading_transfer.html'
            query({
              method: 'getGameLinkByMember',
              params: [
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
                { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
                { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }).then(({ data }) => {

              const gameBalance = data['getGameLinkByMember'].balance
              const gameUrl = data['getGameLinkByMember'].gameLink
              const gameWalletAmount = data['getGameLinkByMember'].wallet_amount

              currentGame.getCurrentGameUrl = gameUrl

              if (gameUrl === "") {
                setLoading(false);

                if (!isInAppBrowser) {
                  mytab.close()
                }else{
                  setLoadingTransfer(false);
                }
                // mytab.close()
                const getResponse = JSON.parse(data['getGameLinkByMember'].response)
                const getResponseErrorMsg = getResponse?.errMsg

                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: getResponseErrorMsg, typeAlert: 'error' }
                });

                return
              } else {

                if (!isInAppBrowser) {
                  mytab.location = gameUrl
                }else{
                  setLoadingTransfer(false);
                }
                // mytab.location = gameUrl

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                }else{
                  if (isInAppBrowser) {
                  window.location.href = gameUrl;
                }
                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {
  
                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })
  
  
                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })
                }


              

              }

            }).catch((error) => {
              // mytab.close()
              if (!isInAppBrowser) {
                mytab.close()
              }
              setLoading(false);
              // console.log('error: ', error)
              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })

          })
          .catch((error) => {
            // console.log('error: ', error)
            // mytab.close()
            if (!isInAppBrowser) {
              mytab.close()
            }
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })




      } else {
        let method = 'getGameLinkByMember';
        let params = [
          { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
          { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
          { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
          { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
          { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
          { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
        ];

        query({
          method,
          params,
          attributes: []

        }).then(({ data }) => {
          // setLoading(false)
          // console.log('getGameLinkByMember', data[method])

          const gameBalance = data[method].balance
          const gameUrl = data[method].gameLink
          const gameWalletAmount = data[method].wallet_amount
          currentGame.getCurrentGameUrl = gameUrl

          if (gameUrl === "") {
            setLoading(false);
            mytab.close()
            const getResponse = JSON.parse(data[method].response)
            const getResponseErrorMsg = getResponse?.errMsg

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: getResponseErrorMsg, typeAlert: 'error' }
            });

            return
          } else {

            if (!isInAppBrowser) {
              mytab.location = '/loading_transfer.html'
            }else{
              setLoading(false);
              setLoadingTransfer(true);
            }

            mutation([{
              method: 'restoreGameWalletByMember',
              params: [
                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }])
              .then(({ data }) => {
                // console.log('data[restoreGameWalletByMember]', data['restoreGameWalletByMember'])

               if (!isInAppBrowser) {
                  mytab.location = gameUrl
                }else{
                  setLoadingTransfer(false);
                 
                }

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                }

                else {
                  if (isInAppBrowser) {
                    window.location.href = gameUrl;
                  }
                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })

                }

              })
              .catch((error) => {
                // console.log('error: ', error)
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                  appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                  });
                }
              })
          }



        }).catch((error) => {
          // mytab.close()
          if (!isInAppBrowser) {
            mytab.close()
          }
          setLoading(false);
          // console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      }



    }
    else {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('error_login_continue'), typeAlert: 'error' }
      });
    }

  }

 

  // GET DATA
  useEffect(() => {
    let filterValue, getDynamicQuery

    if (dataType === 'game provider') {
      // console.log('gameprovider',filter)
      // console.log('dynamicQuery',dynamicQuery)
      if (dynamicQuery !== '') {
        getDynamicQuery = appState.dataTypes?.gameProviders[dynamicQuery]?.where ?? false
      }

      filterValue = {
        where: { '$_game_provider_game_types.type$': `${filter}`, '$_game_provider_game_types.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) },
        order: [['index', 'ASC'], ['name', 'ASC']]
      };

      query({
        method: 'getGameProviders',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: [['code', 'game_provider'], ['_game_provider_game_types', ['type']], 'name', 'live_link', 'auto_transfer_after_game_link']

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGameProviders'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'game') {

      if (dynamicQuery !== '') {
        getDynamicQuery = appState.dataTypes?.games[dynamicQuery].where
      }

      filterValue = { where: { type: `${filter}`, '$_game_provider.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) }, limit: 12 };


      query({
        method: 'getGames',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: ['id', 'name', 'type', 'code', 'game_provider', ['_game_provider', ['status']]]
      }).then(({ data }) => {

        //GAME_${code}
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGames'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'promotion') {

      queries([{
        index: 'getPromotions',
        method: 'getPromotions',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
          { code: 'haveImage', graphqlType: 'Boolean', required: false, value: true },
        ],
        attributes: []
      }, {
        index: 'getPromotionCategories',
        method: 'getPromotionCategories',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },
        ],
        attributes: ['code', 'name']
      }]).then(({ data }) => {

        const getPromotionsData = data['getPromotions']?.map((item) => {
          return { ...item, category: JSON.parse(item.category) };
        })

        // console.log('getPromotionsData: ', getPromotionsData)
        // const getPromoTypes = getPromotionsData.reduce((result, promo) => {
        //   promo.category.forEach((t) => {
        //     if (result.indexOf(t) === -1) {
        //       result.push(t);
        //     }
        //   });
        //   return result
        // }, ["ALL"])



        //  const getPromoTypes = ['ALL', ...data['getPromotionCategories'].map(item => item)];
        const getPromoTypes = [{ code: 'ALL', name: 'show_all' }, ...data['getPromotionCategories'].map(item => item)];

        // console.log('getPromoTypes', getPromoTypes)

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_ALL_TYPES, payload: data['getPromotionCategories'] })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_FILTER_TYPES, payload: getPromoTypes })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_DEFAULT_DATA, payload: getPromotionsData })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: getPromotionsData })

        // console.log('promo', data['getPromotions'])
      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'gameTypes') {
      query({
        method: 'getGameTypes',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
        ],
        attributes: [['type', 'code']]

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGameTypes'] })
        // console.log('get Game Type', data['getGameTypes'])
      }).catch((error) => {
        // console.log('error: ', error)
      })
    }
    else {
      dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data })
      // setDynamicData(data)

    }

  }, [query, queries, data, dataType, filter, i18n.resolvedLanguage, appState.dataTypes?.gameProviders, appState.dataTypes?.games, dynamicQuery])
  const RemainingTime = ({ lastEffectiveDate }) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const year = dayjs(lastEffectiveDate).year()

    useEffect(() => {
      // const promotionEndTime = dayjs(`${lastEffectiveDate}T${displayEndTime}`);
      const promotionEndTime = dayjs(`${lastEffectiveDate}`);

      const intervalId = setInterval(() => {
        const now = dayjs();
        const timeRemaining = promotionEndTime.diff(now);

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setRemainingTime({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [lastEffectiveDate]);

    return (
      <div className="w-50">
        {remainingTime && (
          <div className="promoDetails_timeShow promotionTime_part">
            <div className="promoDetails_timeShow_container">
              {year >= 2999 ? (<>No Expire Date</>) : (<><div className="count_container day_count">
                <div className="count_box">
                  <p>{remainingTime?.days}</p>
                  <p>{t('days')}</p>
                </div>
              </div>
                <div className="count_container time_count">
                  <div className="count_box">
                    <p>{remainingTime?.hours}</p>
                    <p>{t('hour')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.minutes}</p>
                    <p>{t('min')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.seconds}</p>
                    <p>{t('sec')}</p>
                  </div>
                </div></>)}

            </div>
          </div>
        )}
      </div>
    );
  };
  const determineLinkTo = (_item, item) => {
    if (dataType === 'gameTypes') {
      return _item.url;
    }
    if (dataType === 'game provider' && filter === 'SL') {
      return `slots#${item.game_provider}`;
    }

    if (dataType === 'games' || dataType === 'game') {
      return `#${item.game_provider}`;
    }
  };

  const handleLinkClick = (item) => {
    if (dataType === 'game' || dataType === 'games' || (dataType === 'game provider' && filter !== 'SL')) {
      onClickGame(item);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }

    const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

    if (isInAppBrowser) {
      setIsInAppBrowser(true)
    } else {
      setIsInAppBrowser(false)
    }

  }, [])

  const widthColumn = isDesktopOrLaptop ? (rowDesktop ? 100 / rowDesktop : 0) : (rowMobile ? 100 / rowMobile : 0)
  const showDataLength = !numberOfRow ? dynamicState.showData?.length : (numberOfRow !== -1 ? (isDesktopOrLaptop ? rowDesktop : rowMobile) * numberOfRow : dynamicState.showData?.length)


  const bigImageData = Object.values(dynamicState.showData ?? "")[0];
  const smallImagesData = Object.values(dynamicState.showData ?? "").slice(1);

  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} dynamic_template_1 ${noPadding && 'pb-0'}`} id={`layout_03`}>
          <div id={`${id}`}>
            <div className={`${id}_body ${isDesktopOrLaptop ? '' : `${id}_body_m`} layout_body ${noPadding && 'pb-0'}`}>
              <Container>
                {titleToggle ?
                  <div className={`section_header`}>
                    <div className="font_h2 section_title">
                      {titleIconToggle&&(<img src={`${TENANT_BUCKET}/icon/${id}_title_icon.png`} alt="title icon" />)}
                      {label && t(label)}</div>
                    <Link to={`../${dataType === 'game' ? 'slots' : (dataType === 'game provider' ? `${GAME_MENU_DATA[filter].url}` : (dataType === 'promotion' ? 'promotions' : ''))}`} className="section_details">
                      {t('view_more')} &nbsp;
                      <AiOutlineRight />
                    </Link>
                  </div>
                  : <></>}
                {/* <div className="row layout_template3">
              <div className="col-md-4 d-none d-md-block main_pic">
               <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
              </div>
              <div className="col-md-8 col-12">
                <div className="row">
                  <div className="col-4">
                    <div className="mb-1">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-1">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-1">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                 
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="mt-2">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mt-2">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mt-2">
                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} />
                    </div>
                  </div>
                 
                </div>
              </div>
            </div> */}

                {/* <div class="box">
              <div class="left">  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} /></div>
              <div class="right">
                <div>  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} /></div>
                <div>  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} /></div>
              </div>
            </div> */}

                {/* --- SECTION BODY START --- */}
                <div className={`row template_row`}>
                  {imageSize === '1x2' ? (<>
                    <div className="main-image card_container">
                      <div className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType}`} onClick={() => onClickGame(bigImageData)}>
                        {/* --- IMAGE PART START --- */}
                        <figure className="card_image_wrap">
                          {(dataType === 'game' || dataType === 'games') ?
                            <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/img_jpg/${bigImageData?.code}_${imageSize}_w${imageSize === '1x2' ? '750' : '320'}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} /> :
                            (dataType === 'game provider' ?
                              <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${imageSize}/${filter}/${bigImageData?.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                              (dataType === 'gameTypes' ?
                                <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${bigImageData?.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (bigImageData?._desktop_image?.url) : (bigImageData?._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                                  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} />)
                              ))
                          }
                          {/* IMAGE PART CAPTION */}
                          {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                            (dataType === 'game' || dataType === 'games') ?
                              <>{(t(`GAME_${bigImageData?.id}`))}</> :
                              ((dataType === 'promotion' || dataType === 'promotions') ?
                                <>{bigImageData?.title}</> :
                                <>{bigImageData?.name}</>
                              )

                          }</div></figcaption>)}

                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                          {actionPosition === 'hover' && <div className="card_action_wrap">
                            {
                              (dataType === 'game' || dataType === 'games') ?
                                <button className="color_button font_button button_action button_action1" >{bigImageData?.action_1 ?? t('play_now')}</button> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <>
                                    <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(bigImageData)} >{bigImageData.action_1 ?? t('more_info')}</button>
                                    {!appState.loggedIn ?
                                      <button className="color_button font_button button_action button_action2">{bigImageData.action_2 ?? t('join_now')}</button> :
                                      <button className="color_button font_button button_action button_action2">{bigImageData.action_2 ?? t('apply_now')}</button>}
                                  </> :
                                  <></>
                                )

                            }


                          </div>}
                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                        </figure>
                        {/* --- IMAGE PART END --- */}

                      </div>
                    </div>
                    <div className="thumbnails">
                      <div className="thumbnail-row">
                        {(dynamicState.showData ?? "").slice(1, 4).map((item, index) => (
                          <div className="card_container">
                            <div className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType}`} onClick={() => onClickGame(item)}>
                              {/* --- IMAGE PART START --- */}
                              <figure className="card_image_wrap">
                                {(dataType === 'game' || dataType === 'games') ?
                                  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/img_jpg/${item.code}_${imageSize}_w${imageSize === '1x2' ? '320' : '150'}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                  (dataType === 'game provider' ?
                                    <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${imageSize}/${filter}/${item.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                    (dataType === 'gameTypes' ?
                                      <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${item.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                      ((dataType === 'promotion' || dataType === 'promotions') ?
                                        <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (item._desktop_image?.url) : (item._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                        <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/${item.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} />)
                                    ))
                                }
                                {/* IMAGE PART CAPTION */}
                                {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                                  (dataType === 'game' || dataType === 'games') ?
                                    <>{(t(`GAME_${item?.id}`))}</> :
                                    ((dataType === 'promotion' || dataType === 'promotions') ?
                                      <>{item.title}</> :
                                      <>{item.name}</>
                                    )

                                }</div></figcaption>)}

                                {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                                {actionPosition === 'hover' && <div className="card_action_wrap">
                                  {
                                    (dataType === 'game' || dataType === 'games') ?
                                      <button className="color_button font_button button_action button_action1" >{item.action_1 ?? t('play_now')}</button> :
                                      ((dataType === 'promotion' || dataType === 'promotions') ?
                                        <>
                                          <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                          {!appState.loggedIn ?
                                            <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</button> :
                                            <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</button>}
                                        </> :
                                        <></>
                                      )

                                  }


                                </div>}
                                {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                              </figure>
                              {/* --- IMAGE PART END --- */}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="thumbnail-row m-0">

                        {(dynamicState.showData ?? "").slice(4, 7).map((item, index) => (
                          <div className="card_container">
                            <div className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType}`} onClick={() => onClickGame(item)}>
                              {/* --- IMAGE PART START --- */}
                              <figure className="card_image_wrap">
                                {(dataType === 'game' || dataType === 'games') ?
                                     <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/img_jpg/${item.code}_${imageSize}_w${imageSize === '1x2' ? '320' : '150'}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                  (dataType === 'game provider' ?
                                    <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${imageSize}/${filter}/${item.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                    (dataType === 'gameTypes' ?
                                      <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${item.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                      ((dataType === 'promotion' || dataType === 'promotions') ?
                                        <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (item._desktop_image?.url) : (item._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                        <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/${item.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} />)
                                    ))
                                }
                                {/* IMAGE PART CAPTION */}
                                {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                                  (dataType === 'game' || dataType === 'games') ?
                                    <>{(t(`GAME_${item?.id}`))}</> :
                                    ((dataType === 'promotion' || dataType === 'promotions') ?
                                      <>{item.title}</> :
                                      <>{item.name}</>
                                    )

                                }</div></figcaption>)}

                                {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                                {actionPosition === 'hover' && <div className="card_action_wrap">
                                  {
                                    (dataType === 'game' || dataType === 'games') ?
                                      <button className="color_button font_button button_action button_action1" >{item.action_1 ?? t('play_now')}</button> :
                                      ((dataType === 'promotion' || dataType === 'promotions') ?
                                        <>
                                          <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                          {!appState.loggedIn ?
                                            <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</button> :
                                            <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</button>}
                                        </> :
                                        <></>
                                      )

                                  }


                                </div>}
                                {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                              </figure>
                              {/* --- IMAGE PART END --- */}
                            </div>
                          </div>
                        ))}

                      </div>
                    </div>
                  </>) : (<>   <div className="grid-container">
                    {bigImageData&&(<div className="large-game card_container">
                      <div className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType}`} onClick={() => onClickGame(bigImageData)}>
                        {/* --- IMAGE PART START --- */}
                        <figure className="card_image_wrap">
                          {(dataType === 'game' || dataType === 'games') ?
                            <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/img_jpg/${bigImageData?.code}_${imageSize}_w${imageSize === '1x2' ? '750' : '320'}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData?.title} /> :
                            (dataType === 'game provider' ?
                              <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${imageSize}/${filter}/${bigImageData?.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                              (dataType === 'gameTypes' ?
                                <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${bigImageData?.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (bigImageData?._desktop_image?.url) : (bigImageData?._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} /> :
                                  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${bigImageData?.game_provider}/${imageSize}/${bigImageData?.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={bigImageData.title} />)
                              ))
                          }
                          {/* IMAGE PART CAPTION */}
                          {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                            (dataType === 'game' || dataType === 'games') ?
                              <>{(t(`GAME_${bigImageData?.id}`))}</> :
                              ((dataType === 'promotion' || dataType === 'promotions') ?
                                <>{bigImageData?.title}</> :
                                <>{bigImageData?.name}</>
                              )

                          }</div></figcaption>)}

                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                          {actionPosition === 'hover' && <div className="card_action_wrap">
                            {
                              (dataType === 'game' || dataType === 'games') ?
                                <button className="color_button font_button button_action button_action1" >{bigImageData?.action_1 ?? t('play_now')}</button> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <>
                                    <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(bigImageData)} >{bigImageData.action_1 ?? t('more_info')}</button>
                                    {!appState.loggedIn ?
                                      <button className="color_button font_button button_action button_action2">{bigImageData.action_2 ?? t('join_now')}</button> :
                                      <button className="color_button font_button button_action button_action2">{bigImageData.action_2 ?? t('apply_now')}</button>}
                                  </> :
                                  <></>
                                )

                            }


                          </div>}
                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                        </figure>
                        {/* --- IMAGE PART END --- */}

                      </div>
                    </div>)}
                    {(dynamicState.showData ?? "").slice(1, (rowDesktop*2)+1).map((item, index) => (
                      <div className="small-game card_container">
                      <div className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType}`} onClick={() => onClickGame(item)}>
                        {/* --- IMAGE PART START --- */}
                        <figure className="card_image_wrap">
                          {(dataType === 'game' || dataType === 'games') ?
                              <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/img_jpg/${item.code}_${imageSize}_w${imageSize === '1x2' ? '320' : '150'}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                            (dataType === 'game provider' ?
                              <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${imageSize}/${filter}/${item.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                              (dataType === 'gameTypes' ?
                                <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${item.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (item._desktop_image?.url) : (item._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                  <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/${item.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} />)
                              ))
                          }
                          {/* IMAGE PART CAPTION */}
                          {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                            (dataType === 'game' || dataType === 'games') ?
                              <>{(t(`GAME_${item?.id}`))}</> :
                              ((dataType === 'promotion' || dataType === 'promotions') ?
                                <>{item.title}</> :
                                <>{item.name}</>
                              )

                          }</div></figcaption>)}

                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                          {actionPosition === 'hover' && <div className="card_action_wrap">
                            {
                              (dataType === 'game' || dataType === 'games') ?
                                <button className="color_button font_button button_action button_action1" >{item.action_1 ?? t('play_now')}</button> :
                                ((dataType === 'promotion' || dataType === 'promotions') ?
                                  <>
                                    <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                    {!appState.loggedIn ?
                                      <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</button> :
                                      <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</button>}
                                  </> :
                                  <></>
                                )

                            }


                          </div>}
                          {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                        </figure>
                        {/* --- IMAGE PART END --- */}
                      </div>
                    </div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 1" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 2" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 3" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 4" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 5" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 1" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 2" /></div>
                    // <div className="small-game"><img src="https://storage.googleapis.com/images.imbaweb.com/game/NT/1x1/img/214_41_1x1_w150.png" alt="Small Game 3" /></div>
                    ))}
                  </div></>)}
                </div>


                {/* --- SECTION BODY END --- */}

              </Container></div></div>
        </section >
        {isLoading && <Loading />}
        {isLoadingTransfer && <Loading message={'Transferring funds to the game ...'}/>}
      </>
    );
  };
}


export default Template_03;
